export const riskOptions = [
  {
    label: 'All',
    value: "all"
  },
  {
    label: 'Identified',
    value: true
  },
  {
    label: 'Unidentified',
    value: false
  },
]

export const pagesOptions = [5, 10,20, 50, 100]

export const addressNumberOptions = [
  {
    label: 'Any',
    value: "any"
  },
  {
    label: 'More than',
    value: 'more'
  },
  {
    label: 'Less',
    value: 'less'
  },
]
